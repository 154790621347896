import { rest } from "msw";

import { getShipdaDevNewAPI } from "../../../services/msw";

import { ARTIFICIAL_DELAY_MS } from "../../../constants";
import { CUSTOMS_LIST } from "./data";

// 관세사 리스트 조회
export const getCustomsList = rest.get(
  getShipdaDevNewAPI("/partner"),
  (req, res, ctx) =>
    res(ctx.status(200), ctx.delay(ARTIFICIAL_DELAY_MS), ctx.json(CUSTOMS_LIST))
);

// 관세사 신규 등록
export const registerCustoms = rest.post(
  getShipdaDevNewAPI("/partner/:bidId"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({ result: "ok" })
    );
  }
);

// 관세사 선택 / 선택 변경
export const selectBidCustomsMail = rest.put(
  getShipdaDevNewAPI("partner/:bidId"),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.delay(ARTIFICIAL_DELAY_MS),
      ctx.json({ result: "ok" })
    );
  }
);
