import { useAppMutation } from "../../../services/query";

import {
  CHANGE_CUSTOMS_ACTIVATE_FLAG_REQ,
  CHANGE_CUSTOMS_ACTIVATE_FLAG_REQ_PARAMS,
  CHANGE_CUSTOMS_ACTIVATE_FLAG_RES,
} from "../../../api-interfaces/shipda-api/admin/adminCustomsPartner";

export const CUSTOMS_PARTNER_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/CUSTOMS_BID_QUERY" }] as const,
};

function useChangeCustomsActivateFlag() {
  const mutation = useAppMutation<
    CHANGE_CUSTOMS_ACTIVATE_FLAG_REQ,
    CHANGE_CUSTOMS_ACTIVATE_FLAG_RES,
    CHANGE_CUSTOMS_ACTIVATE_FLAG_REQ_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ managerId }) => `/partner/customsAdmin/${managerId}`,
      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...mutation };
}

const CUSTOMS_PARTNER_QUERY = {
  useChangeCustomsActivateFlag,
};

export default CUSTOMS_PARTNER_QUERY;
