import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "../../../services/query";
import { ResponseFailureInfo } from "../../../types/common/common";

import {
  CREATE_ACCOUNT_REQ,
  CREATE_ACCOUNT_RES,
  GET_ACCOUNT_CREDENTIAL_REQ,
  GET_ACCOUNT_CREDENTIAL_RES,
  GET_ACCOUNT_DETAIL_REQ,
  GET_ACCOUNT_DETAIL_RES,
  GET_ACCOUNT_LIST_REQ,
  GET_ACCOUNT_LIST_RES,
  GET_MAPPED_ACCOUNT_RES,
  GET_MAPPING_ACCOUNT_REQ,
  GET_MAPPING_ACCOUNT_RES,
  UPDATE_ACCOUNT_DETAIL_PATH_PARAMS,
  UPDATE_ACCOUNT_DETAIL_REQ,
  UPDATE_ACCOUNT_DETAIL_RES,
} from "../../../api-interfaces/shipda-api/admin/adminAccount";

export const ADMIN_ACCOUNT_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_ACCOUNT_QUERY" }] as const,

  getAccountList: (params: GET_ACCOUNT_LIST_REQ) =>
    [
      {
        ...ADMIN_ACCOUNT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "accountList",
      },
    ] as const,

  getAccountDetail: ({ id }: GET_ACCOUNT_DETAIL_REQ) =>
    [
      {
        ...ADMIN_ACCOUNT_QUERY_KEY_GEN.all()[0],
        entity: "accountDetail",
        id,
      },
    ] as const,

  getAccountCredential: ({ id, type }: { id: number; type: string }) =>
    [
      {
        ...ADMIN_ACCOUNT_QUERY_KEY_GEN.all()[0],
        entity: "accountCredential",
        id,
        type,
      },
    ] as const,

  getMappedAccount: ({ id }: { id: number }) =>
    [
      {
        ...ADMIN_ACCOUNT_QUERY_KEY_GEN.all()[0],
        entity: "mappedAccount",
        id,
      },
    ] as const,

  getMappingAccountList: (params: GET_MAPPING_ACCOUNT_REQ) =>
    [
      {
        ...ADMIN_ACCOUNT_QUERY_KEY_GEN.all()[0],
        entity: "mappingAccountList",
        ...params,
      },
    ] as const,
};

function useGetAccountList(
  params: GET_ACCOUNT_LIST_REQ,
  { enabled }: { enabled?: boolean }
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_ACCOUNT_QUERY_KEY_GEN.getAccountList>,
    GET_ACCOUNT_LIST_RES
  >({
    queryKey: ADMIN_ACCOUNT_QUERY_KEY_GEN.getAccountList(params),
    requestOptions: {
      method: "get",
      path: "/fin-account",
      apiType: "SHIPDA_ADMIN_API",
      params,
    },
    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useCreateAccount() {
  const mutation = useAppMutation<CREATE_ACCOUNT_REQ, CREATE_ACCOUNT_RES>({
    requestOptions: {
      method: "post",
      path: `/fin-account`,
      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...mutation };
}

function useGetAccountDetail({ id }: GET_ACCOUNT_DETAIL_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_ACCOUNT_QUERY_KEY_GEN.getAccountDetail>,
    GET_ACCOUNT_DETAIL_RES
  >({
    queryKey: ADMIN_ACCOUNT_QUERY_KEY_GEN.getAccountDetail({ id }),
    requestOptions: {
      method: "get",
      path: `/fin-account/${id}`,
      apiType: "SHIPDA_ADMIN_API",
    },

    enabled: Boolean(id),
  });

  return { ...queryResult };
}

function useUpdateAccountDetail() {
  const mutation = useAppMutation<
    UPDATE_ACCOUNT_DETAIL_REQ,
    UPDATE_ACCOUNT_DETAIL_RES,
    UPDATE_ACCOUNT_DETAIL_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ id }) => `/fin-account/${id}`,
      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...mutation };
}

function useGetAccountCredential({
  id,
  type,
  enabled,
  onSuccess,
}: GET_ACCOUNT_CREDENTIAL_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_ACCOUNT_QUERY_KEY_GEN.getAccountCredential>,
    GET_ACCOUNT_CREDENTIAL_RES
  >({
    queryKey: ADMIN_ACCOUNT_QUERY_KEY_GEN.getAccountCredential({ id, type }),
    requestOptions: {
      method: "get",
      path: `/fin-account/${id}/reveal`,
      apiType: "SHIPDA_ADMIN_API",
      params: {
        type,
      },
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),
    onSuccess,
  });

  return { ...queryResult };
}

/** 특정(id) 거래처에 매핑된 팀, 파트너 데이터 */
function useGetMappedAccountList({
  id,
  enabled,
  onSuccess,
  onError,
}: {
  id: number;
  enabled: boolean;
  onSuccess: (data: GET_MAPPED_ACCOUNT_RES) => void;
  onError: (failureInfo: ResponseFailureInfo | undefined) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_ACCOUNT_QUERY_KEY_GEN.getMappedAccount>,
    GET_MAPPED_ACCOUNT_RES
  >({
    queryKey: ADMIN_ACCOUNT_QUERY_KEY_GEN.getMappedAccount({ id }),
    requestOptions: {
      method: "get",
      path: `/fin-account/${id}/mapping`,
      apiType: "SHIPDA_ADMIN_API",
    },

    onSuccess(data) {
      onSuccess(data);
    },
    onError,
    enabled,
  });

  return { ...queryResult };
}

/** 새로 연결할 팀이나 파트너 데이터 */
function useGetMappingAccountList(
  params: GET_MAPPING_ACCOUNT_REQ,
  enabled: boolean
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_ACCOUNT_QUERY_KEY_GEN.getMappingAccountList>,
    GET_MAPPING_ACCOUNT_RES
  >({
    queryKey: ADMIN_ACCOUNT_QUERY_KEY_GEN.getMappingAccountList(params),
    requestOptions: {
      method: "get",
      path: `/fin-account/mapping/candidate`,
      apiType: "SHIPDA_ADMIN_API",
      params,
    },

    enabled,
  });

  return { ...queryResult };
}

const ADMIN_ACCOUNT_QUERY = {
  useGetAccountList,
  useCreateAccount,
  useGetAccountDetail,
  useUpdateAccountDetail,
  useGetAccountCredential,
  useGetMappedAccountList,
  useGetMappingAccountList,
};

export default ADMIN_ACCOUNT_QUERY;
