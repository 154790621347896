import { SellernoteAPIType } from "../types/common/common";

/**
 * MSW에서 사용하는 API
 * - 실제 소스코드에서는 사용해서는 안 됨.
 */
const MSW_API: Record<SellernoteAPIType, string> = {
  SHIPDA_API: "https://api-dev.ship-da.com",
  SHIPDA_ADMIN_API: "https://admin-api-dev.ship-da.com",
  BOFUL_API: "https://api.tall.boful.seller-note.com",
  CMS_API_V2: "https://contents-api-v2-dev.ship-da.com",
  TRACKING_API: "https://tracking-api-dev.ship-da.com",
  BOFUL_DASHBOARD_API: "https://dashboard-api-dev.ship-da.com",
  LOCAL_PRINTER: "http://localhost:3000",
  NETWORK_PRINTER_FOR_HANJIN_AND_OVERSEAS:
    "https://network-printer-for-hanjin-and-overseas-dev.ship-da.com",
  NETWORK_PRINTER_FOR_CJ: "https://network-printer-for-cj-dev.ship-da.com",
};

export default MSW_API;
