import { BidProjectStatus, BidStatus } from "../../../types/forwarding/bid";

/**
 * 주어진 이유 코드를 한국어 이름으로 변환합니다.
 *
 * @param reason - 이유 코드 문자열
 * @returns 한국어 이름 문자열
 */
export const changeProceedReasonToNameKr = (reason: string) => {
  switch (reason) {
    case "underReview":
      return "견적 검토 중";
    case "changeBidProceed":
      return "새로운 견적 의뢰";
    case "scheduleProceed":
      return "진행 예정";
    case "inPrepared":
      return "진행 계획이나 화물 준비 중";
    case "unconfirmed":
      return "수입 계획 미확정";
    case "reviewLater":
      return "추후 진행 시 재검토";
    case "changeConditions":
      return "수입조건 변경";
    case "anotherBid":
      return "다른 의뢰 번호로 수주 완료";
    case "price":
      return "가격 경쟁력 부족";
    case "competitors":
      return "기존 포워더 사용";
    case "lostContact":
      return "연락 두절";
    case "cancelPlan":
      return "수입계획 취소";
    case "missed":
      return "부재중";
    case "noProgress":
      return "진행 물량 없음";
    case "fareCheck":
      return "단순 운임 체크";
    default:
      return "-";
  }
};

/**
 * 주어진 비즈니스 관리 항목의 상태를 한국어 이름으로 변환합니다.
 *
 * @param status - 견적 상태
 * @param projectStatus - (선택 사항) 프로젝트 상태
 * @returns 한국어 이름 문자열
 */
export const changeBusinessManagementStatusToNameKr = (
  status: BidStatus,
  projectStatus?: BidProjectStatus
) => {
  switch (status) {
    case "committed":
      return "의뢰 받음";
    case "waiting":
      return "사용자 선택 대기 중";
    case "waitingForExporterInfo":
      return "수출자 정보 입력 대기 중";
    case "estimating":
      return "견적 산출 중";
    case "finished":
      return projectStatus === "failed" ? "실패" : "취소";
    default:
      return "-";
  }
};

/**
 * 주어진 비즈니스 관리 항목의 서비스 유형을 한국어 이름으로 변환합니다.
 *
 * @param serviceType - 서비스 유형
 * @returns 한국어 이름 문자열
 */
export const changeBusinessManagementServiceTypeToNameKr = (
  serviceType: string
) => {
  switch (serviceType) {
    case "general":
      return "일반 의뢰";
    case "oceanTicket":
      return "오션티켓";
    case "consolidation":
      return "창고입고 후 수입운송";
    default:
      return "-";
  }
};

/**
 * 주어진 서비스 유형을 한국어 이름으로 변환합니다.
 *
 * @param serviceType - 서비스 유형
 * @returns 한국어 이름 문자열
 */
export const changeServiceTypeToNameKr = (serviceType: string) => {
  switch (serviceType) {
    case "general":
      return "일반 의뢰";
    case "oceanTicket":
      return "오션티켓";
    case "consolidation":
      return "특송";
    default:
      return "-";
  }
};
