import {
  SellernoteAPIType,
  SellernoteUserAppName,
} from "../types/common/common";

import { APP_NAME } from "../constants";

/**
 * 프린터 관련 URL
 * - 배포 환경에 따라 달라지지 않으므로 환경변수가 아닌 하드코딩된 값을 사용
 */
const LOCAL_PRINTER_URL = "http://localhost:1457";
const NETWORK_PRINTER_FOR_HANJIN_AND_OVERSEAS_URL =
  "http://192.168.60.201:9100";
const NETWORK_PRINTER_FOR_CJ_URL = "http://localhost:18080/print";
const LOCAL_PRINTER_FIRST_URL = "192.168.60.231:9100";

/**
 * API Type별로 각 App에서 환경변수로 제공하는 값들이 정의된 레코드
 */
const API_URL_BY_APP: Record<
  SellernoteAPIType,
  Partial<Record<SellernoteUserAppName, string>>
> = {
  SHIPDA_API: {
    // (Dev 값)https://api-dev.ship-da.com
    "shipda-kr": process.env.NEXT_PUBLIC_API_URL_NEW,
  },
  CMS_API_V2: {
    // (Dev 값)https://contents-api-v2-dev.ship-da.com
    "shipda-kr": process.env.NEXT_PUBLIC_CONTENTS_API_URL,
  },
  TRACKING_API: {
    // (Dev 값)https://tracking-api-dev.ship-da.com
    "shipda-kr": process.env.NEXT_PUBLIC_TRACKING_API_URL,
  },
  SHIPDA_ADMIN_API: {
    // (Dev 값)https://admin-api-dev.ship-da.com
    "shipda-admin": process.env.REACT_APP_ADMIN_URL,
    "partner-admin": process.env.REACT_APP_ADMIN_URL,
  },
  BOFUL_API: {
    // (Dev 값)https://api.tall.boful.seller-note.com
    "shipda-kr": process.env.NEXT_PUBLIC_BOFUL_API_URL,
    "boful-worker-pda": process.env.REACT_APP_API_URL,
    "boful-worker-web": process.env.REACT_APP_API_URL,
  },
  BOFUL_DASHBOARD_API: {
    // (Dev 값)https://dash-api.tall.boful.seller-note.com
    "shipda-kr": process.env.NEXT_PUBLIC_BOFUL_DASHBOARD_API_URL,
  },
  LOCAL_PRINTER: {
    "boful-worker-pda": LOCAL_PRINTER_URL,
    "boful-worker-web": LOCAL_PRINTER_URL,
  },
  NETWORK_PRINTER_FOR_HANJIN_AND_OVERSEAS: {
    "boful-worker-pda": NETWORK_PRINTER_FOR_HANJIN_AND_OVERSEAS_URL,
    "boful-worker-web": NETWORK_PRINTER_FOR_HANJIN_AND_OVERSEAS_URL,
  },
  NETWORK_PRINTER_FOR_CJ: {
    "boful-worker-pda": NETWORK_PRINTER_FOR_CJ_URL,
    "boful-worker-web": NETWORK_PRINTER_FOR_CJ_URL,
  },
};

/**
 * 각 APP에서 auth API로 사용하는 API Type
 */
const AUTH_API_BY_APP: Record<SellernoteUserAppName, SellernoteAPIType> = {
  "shipda-kr": "SHIPDA_API",
  "boful-worker-pda": "BOFUL_API",
  "boful-worker-web": "BOFUL_API",
  "shipda-admin": "SHIPDA_ADMIN_API",
  "partner-admin": "SHIPDA_ADMIN_API",
};

/**
 * 각 APP에서 인증용 API로 사용하는 API의 baseURL
 */
const APP_BASE_URL_FOR_AUTH = (() => {
  const apiTypeForAuth = AUTH_API_BY_APP[APP_NAME as SellernoteUserAppName];

  const baseURL =
    API_URL_BY_APP[apiTypeForAuth]?.[APP_NAME as SellernoteUserAppName];
  if (!baseURL) {
    // 정상적인 flow라면 auth용 baseURL은 반드시 있어야 한다.
    throw Error("Not found baseURL for auth");
  }

  return baseURL;
})();

export {
  API_URL_BY_APP,
  AUTH_API_BY_APP,
  APP_BASE_URL_FOR_AUTH,
  LOCAL_PRINTER_URL,
  NETWORK_PRINTER_FOR_HANJIN_AND_OVERSEAS_URL,
  NETWORK_PRINTER_FOR_CJ_URL,
  LOCAL_PRINTER_FIRST_URL,
};
