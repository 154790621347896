const createInfoContainer = ({
  className,
  titleText,
  portName,
  infoIconImgSrc,
}: {
  className: string;
  titleText: string;
  portName: string | undefined;
  infoIconImgSrc: string;
}) => {
  const infoContainer = document.createElement("div");
  infoContainer.className = "info-container";

  const div = document.createElement("div");
  div.className = className;

  const title = document.createElement("div");
  title.className = "title";

  const titleImg = document.createElement("img");
  titleImg.src = infoIconImgSrc;

  const titleTextElement = document.createElement("div");
  titleTextElement.textContent = titleText;

  title.appendChild(titleImg);
  title.appendChild(titleTextElement);
  div.appendChild(title);

  const port = document.createElement("div");
  port.className = "port";
  port.textContent = portName || "";

  div.appendChild(port);

  infoContainer.appendChild(div);

  return infoContainer;
};

const createWaypointMarker = () => {
  const waypointMarker = document.createElement("div");
  waypointMarker.className = "waypoint-marker";

  const circle = document.createElement("div");
  circle.className = "circle";

  waypointMarker.appendChild(circle);

  return waypointMarker;
};

const createSvgMarker = (svg: string) => {
  const newSvg = new DOMParser().parseFromString(
    svg,
    "image/svg+xml"
  ).documentElement;

  // 스타일 적용
  newSvg.style.position = "absolute";
  newSvg.style.transform = "translate(-50%, -50%)";

  return newSvg;
};

export { createInfoContainer, createWaypointMarker, createSvgMarker };
