import { useAppQueryWithQueryKeyFactory } from "@sellernote/_shared/src/services/query";

import { FreightType } from "../../../types/common/common";

import {
  GET_VESSEL_SCHEDULE_LIST_REQ,
  GET_VESSEL_SCHEDULE_LIST_RES,
  GET_VESSEL_SCHEDULE_SELECT_LIST_REQ,
  GET_VESSEL_SCHEDULE_SELECT_LIST_RES,
} from "../../../api-interfaces/shipda-api/admin/adminVesselSchedule";

export const ADMIN_VESSEL_SCHEDULE_QUERY_KEY_GEN = {
  all: () => [{ scope: "fulfillment/ADMIN_VESSEL_SCHEDULE_QUERY" }] as const,

  getVesselScheduleList: (
    { ...params }: GET_VESSEL_SCHEDULE_LIST_REQ,
    freightType: FreightType
  ) =>
    [
      {
        ...ADMIN_VESSEL_SCHEDULE_QUERY_KEY_GEN.all()[0],
        entity: "getVesselScheduleList",
        freightType,
        ...params,
      },
    ] as const,

  getVesselScheduleSelectList: (params: GET_VESSEL_SCHEDULE_SELECT_LIST_REQ) =>
    [
      {
        ...ADMIN_VESSEL_SCHEDULE_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getVesselScheduleSelectList",
      },
    ] as const,
};

function useGetVesselScheduleList(
  { ...params }: GET_VESSEL_SCHEDULE_LIST_REQ,
  freightType: FreightType
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof ADMIN_VESSEL_SCHEDULE_QUERY_KEY_GEN.getVesselScheduleList
    >,
    GET_VESSEL_SCHEDULE_LIST_RES
  >({
    queryKey: ADMIN_VESSEL_SCHEDULE_QUERY_KEY_GEN.getVesselScheduleList(
      {
        ...params,
      },
      freightType
    ),
    requestOptions: {
      method: "get",
      path: `/vesselSchedule/schedules/${freightType}`,
      params: {
        ...params,
      },
      apiType: "SHIPDA_ADMIN_API",
    },
    keepPreviousData: true, // 페이징을 할때만 true로 사용하면 됨
  });

  return { ...queryResult };
}

function useGetVesselScheduleSelectList(
  params: GET_VESSEL_SCHEDULE_SELECT_LIST_REQ
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof ADMIN_VESSEL_SCHEDULE_QUERY_KEY_GEN.getVesselScheduleSelectList
    >,
    GET_VESSEL_SCHEDULE_SELECT_LIST_RES
  >({
    queryKey:
      ADMIN_VESSEL_SCHEDULE_QUERY_KEY_GEN.getVesselScheduleSelectList(params),
    requestOptions: {
      method: "get",
      path: `/vesselSchedule/selectors/${params.freightType}`,
      params: {
        includeWarehouse: params.includeWarehouse,
      },
      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...queryResult };
}

const ADMIN_VESSEL_SCHEDULE_QUERY = {
  useGetVesselScheduleList,
  useGetVesselScheduleSelectList,
};
export default ADMIN_VESSEL_SCHEDULE_QUERY;
