import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "../../../services/query";
import { ResponseFailureInfo } from "../../../types/common/common";

import {
  CONTACT_PARTNER_REQ,
  CONTACT_PARTNER_RES,
  CREATE_PO_REQ,
  CREATE_PO_RES,
  CREATE_SUB_PO_REQ,
  CREATE_SUB_PO_RES,
  DELETE_SUB_PO_REQ,
  DELETE_SUB_PO_RES,
  GET_ADMIN_ORDER_DETAIL_REQ,
  GET_ADMIN_ORDER_DETAIL_RES,
  GET_ADMIN_ORDER_LIST_RES,
  GET_ADMIN_ORDER_PARAMS,
  MAPPING_SHIPMENT_REQ,
  MAPPING_SHIPMENT_RES,
  REMOVE_MAPPED_SHIPMENT_REQ,
  REMOVE_MAPPED_SHIPMENT_RES,
  UPDATE_ADMIN_ORDER_EXPORTER_REQ,
  UPDATE_ADMIN_ORDER_EXPORTER_RES,
  UPDATE_ORDER_COMMENT_REQ,
  UPDATE_ORDER_COMMENT_RES,
  UPDATE_ORDER_PARTNER_REQ,
  UPDATE_ORDER_PARTNER_RES,
  UPDATE_PO_NUMBER_REQ,
} from "../../../api-interfaces/shipda-api/admin/adminOrder";

export const ADMIN_ORDER_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_ORDER_QUERY_KEY_GEN" }] as const,

  getAdminOrderList: (params: GET_ADMIN_ORDER_PARAMS) =>
    [
      {
        ...ADMIN_ORDER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminOrderList",
      },
    ] as const,
  getAdminOrderDetail: (params: GET_ADMIN_ORDER_DETAIL_REQ) =>
    [
      {
        ...ADMIN_ORDER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getAdminOrderDetail",
      },
    ] as const,
};

function useGetAdminOrderList(
  params: GET_ADMIN_ORDER_PARAMS,
  enabled?: boolean
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_ORDER_QUERY_KEY_GEN.getAdminOrderList>,
    GET_ADMIN_ORDER_LIST_RES
  >({
    queryKey: ADMIN_ORDER_QUERY_KEY_GEN.getAdminOrderList(params),
    requestOptions: {
      method: "get",
      path: `/purchaseOrder`,
      apiType: "SHIPDA_ADMIN_API",
      params,
    },

    keepPreviousData: true,
    enabled,
    failureModalInfo: {
      customizeMessage: () => ({
        title: "발주 리스트 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useGetAdminOrderDetail(params: GET_ADMIN_ORDER_DETAIL_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_ORDER_QUERY_KEY_GEN.getAdminOrderDetail>,
    GET_ADMIN_ORDER_DETAIL_RES
  >({
    queryKey: ADMIN_ORDER_QUERY_KEY_GEN.getAdminOrderDetail(params),
    requestOptions: {
      method: "get",
      path: `/purchaseOrder/${params.id}`,
      apiType: "SHIPDA_ADMIN_API",
    },

    keepPreviousData: true,
    failureModalInfo: {
      customizeMessage: () => ({
        title: "발주 상세 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useUpdateAdminOrderDetailExporterInfo(POId: string | number) {
  const mutation = useAppMutation<
    UPDATE_ADMIN_ORDER_EXPORTER_REQ,
    UPDATE_ADMIN_ORDER_EXPORTER_RES
  >({
    requestOptions: {
      method: "patch",
      path: `/purchaseOrder/exporter/${POId}`,
      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...mutation };
}

function useUpdateOrderPartner() {
  const mutation = useAppMutation<
    UPDATE_ORDER_PARTNER_REQ,
    UPDATE_ORDER_PARTNER_RES
  >({
    requestOptions: {
      method: "put",
      path: `/purchaseOrder/partnerManagers`,
      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...mutation };
}

function useUpdateComment() {
  const mutation = useAppMutation<
    UPDATE_ORDER_COMMENT_REQ,
    UPDATE_ORDER_COMMENT_RES
  >({
    requestOptions: {
      method: "post",
      path: `/purchaseOrder/memo`,
      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...mutation };
}

function useDeleteSubPO(POId: number) {
  const mutation = useAppMutation<DELETE_SUB_PO_REQ, DELETE_SUB_PO_RES>({
    requestOptions: {
      method: "delete",
      path: `/purchaseOrder/subPo/${POId}`,
      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...mutation };
}

function useCreateSubPO(POId: number) {
  const mutation = useAppMutation<CREATE_SUB_PO_REQ, CREATE_SUB_PO_RES>({
    requestOptions: {
      method: "post",
      path: `/purchaseOrder/subPo/${POId}`,
      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...mutation };
}

function useContactPartner(POId: number) {
  const mutation = useAppMutation<CONTACT_PARTNER_REQ, CONTACT_PARTNER_RES>({
    requestOptions: {
      method: "post",
      path: `/purchaseOrder/email/${POId}`,
      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...mutation };
}

function useCreatePO() {
  const mutation = useAppMutation<CREATE_PO_REQ, CREATE_PO_RES>({
    requestOptions: {
      method: "post",
      path: `/purchaseOrder`,
      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return { ...mutation };
}

function useUpdatePONumber({
  poId,
  onSuccess,
  onError,
}: {
  poId: number;
  onSuccess?: () => void;
  onError?: (err: ResponseFailureInfo | undefined) => void;
}) {
  const mutation = useAppMutation<UPDATE_PO_NUMBER_REQ, unknown>({
    requestOptions: {
      method: "patch",
      path: `/purchaseOrder/number/${poId}`,
      apiType: "SHIPDA_ADMIN_API",
    },

    onSuccess,
    onError(err) {
      onError?.(err);
    },
  });

  return { ...mutation };
}

function useMappingShipment({
  poId,
  onSuccess,
}: {
  poId: number;
  onSuccess: () => void;
}) {
  const mutation = useAppMutation<MAPPING_SHIPMENT_REQ, MAPPING_SHIPMENT_RES>({
    requestOptions: {
      method: "post",
      path: `/purchaseOrder/mapping/${poId}`,
      apiType: "SHIPDA_ADMIN_API",
    },
    onSuccess,
  });

  return { ...mutation };
}

function useRemoveMappedShipment({
  poId,
  onSuccess,
}: {
  poId: number;
  onSuccess: () => void;
}) {
  const mutation = useAppMutation<
    REMOVE_MAPPED_SHIPMENT_REQ,
    REMOVE_MAPPED_SHIPMENT_RES
  >({
    requestOptions: {
      method: "delete",
      path: `/purchaseOrder/mapping/${poId}`,
      apiType: "SHIPDA_ADMIN_API",
    },

    onSuccess,
  });

  return { ...mutation };
}

const ADMIN_ORDER_QUERY = {
  useGetAdminOrderList,
  useGetAdminOrderDetail,
  useUpdateAdminOrderDetailExporterInfo,
  useUpdateOrderPartner,
  useUpdateComment,
  useDeleteSubPO,
  useCreateSubPO,
  useContactPartner,
  useCreatePO,
  useUpdatePONumber,
  useMappingShipment,
  useRemoveMappedShipment,
};

export default ADMIN_ORDER_QUERY;
