import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/_shared/src/services/query";

import {
  QueryResponseHandlerFailureModalInfo,
  QueryResponseHandlerSuccessModalInfo,
} from "../../components/QueryResponseHandler";

import {
  GET_BID_EXPORTER_LIST_REQ,
  GET_BID_EXPORTER_LIST_RES,
  UPDATE_BID_EXPORTER_LIST_REQ,
  UPDATE_BID_EXPORTER_LIST_RES,
} from "../../api-interfaces/shipda-api/bidExporter";

export const ADMIN_BID_EXPORTER_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/BID_EXPORTER_QUERY" }] as const,

  getBidExporterList: (params: GET_BID_EXPORTER_LIST_REQ) =>
    [
      {
        ...ADMIN_BID_EXPORTER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getBidExporterList",
      },
    ] as const,

  getBidExporterDetail: (params: GET_BID_EXPORTER_LIST_REQ) =>
    [
      {
        ...ADMIN_BID_EXPORTER_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getBidExporterDetail",
      },
    ] as const,
};

function useGetExportersInfo(params: GET_BID_EXPORTER_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_EXPORTER_QUERY_KEY_GEN.getBidExporterList>,
    GET_BID_EXPORTER_LIST_RES
  >({
    queryKey: ADMIN_BID_EXPORTER_QUERY_KEY_GEN.getBidExporterList(params),
    requestOptions: {
      method: "get",
      path: `/exporters`,
      params,
      apiType: "SHIPDA_ADMIN_API",
    },
  });

  return {
    ...queryResult,
  };
}

function useUpdateBidExporter(
  id: number,
  options?: {
    successModalInfo?: QueryResponseHandlerSuccessModalInfo;
    failureModalInfo?: QueryResponseHandlerFailureModalInfo;
  }
) {
  const mutation = useAppMutation<
    UPDATE_BID_EXPORTER_LIST_REQ,
    UPDATE_BID_EXPORTER_LIST_RES
  >({
    requestOptions: {
      method: "put",
      path: `/exporters/${id}`,
      apiType: "SHIPDA_ADMIN_API",
    },

    successModalInfo: options?.successModalInfo,
    failureModalInfo: options?.failureModalInfo,
  });

  return { ...mutation };
}

const BID_EXPORTER_QUERY = {
  useGetExportersInfo,
  useUpdateBidExporter,
};
export default BID_EXPORTER_QUERY;
